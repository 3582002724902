import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Subscription } from 'store/types/Subscriptions';
import moment from 'moment';
import collega from '../../image/collega.svg';
import professional from '../../image/icons/activity/collega-gold.svg';
import gold from '../../image/gold.svg';
import colors from 'values/colors';
import { ActivityModal } from 'store/types/ActivityModal';
import ConfirmModal from 'components/request-activities/modal/ConfirmModal';
import { DateFormat, SubscriptionType } from 'values/values';
import Utility from 'utils/utils';
import Modal from 'components/common/Modal';
import UserService from 'services/UserServices';
import SubscriptionsEdit from './SubscriptionsEdit';
import PlanService from 'services/PlanService';
import toast from 'react-hot-toast';

type Props = {
    subscription: Subscription
    onPress: (subscription: Subscription) => void
}

const SubscriptionsEditCard: React.FC<Props> = ({ subscription, onPress }) => {

    let [cancelSubscriptionUserResponse, cancelSubscriptionUserError, cancelSubscriptionUserRequest] = UserService.useCancelSubscriptionUserAPI()
    let [changePlanResponse, changePlanError, changePlanRequest] = PlanService.useChangePlansAPI()
    let [userResponse, userError, userRequest] = UserService.useUserAPI()

    const [changePlanModal, setChangePlanModal] = useState(false)
    const [cancelSubscriptionsPopup, setCancelSubscriptionsPopup] = useState(false)


    useEffect(() => {
        if (cancelSubscriptionUserResponse) {
            setCancelSubscriptionsPopup(false)

            userRequest()
        }
    }, [cancelSubscriptionUserResponse]);

    useEffect(() => {
        if (changePlanResponse) {
            toast.success("Piano aggiornato con successo!");
            setChangePlanModal(false)
            userRequest()
        }
    }, [changePlanResponse]);

    useEffect(() => {
        if (changePlanError) {
            toast.success("Qualcosa non ha funzionato correttamente. Ti invitiamo a riprovare più tardi.");
            setChangePlanModal(false)
        }
    }, [changePlanError]);


    let subscriptionIcon = (subscription: Subscription) => {
        switch (subscription.type) {
            case SubscriptionType.PROFESSIONAL: return professional
            case SubscriptionType.SKILLS: return collega
            case SubscriptionType.GOLD: return gold
            case SubscriptionType.BUNDLE: return collega
        }
    }


    const handleCancelSubscription = (subscription?: Subscription) => {
        if (subscription && subscription.id) {
            cancelSubscriptionUserRequest(subscription.id);
        }
    };

    const cancelSubscriptionPopupView = (subscription: Subscription) => {
        // Check if subscription exists and has an expiration date; default to now if not
        const expirationDate = subscription && subscription.expire_at ? moment(subscription.expire_at) : moment();

        // Format the expiration date
        const subscription_expiration_date = expirationDate.format(DateFormat.full);

        const subscription_description = `Se procedi con l'annullamento ora, potrai continuare ad utilizzare il tuo abbonamento ${subscription.title} fino al ${subscription_expiration_date}.`

        // Create the activity modal for canceling the subscription
        const cancelSubscription: ActivityModal = {
            title: 'Attenzione',
            copy: subscription_description,
            cta: 'Disdici abbonamento',
            ctaUndo: 'Annulla',
            onConfirm: () => {
                handleCancelSubscription(subscription)
            },
            onCancel: () => setCancelSubscriptionsPopup(false) // Close the popup
        };

        return cancelSubscription;
    };

    const renderSubscription = () => {
        let expiration_date = moment(subscription.expire_at).format(DateFormat.full);

        let description = subscription.unsubscribed
            ? `L'abbonamento scadrà in data ${expiration_date}. Potrai continuare ad utilizzare l'abbonamento fino alla data di scadenza.`
            : subscription.next_plan != undefined ? `Per questo abbonamento è stato richiesto un cambio piano che si applicherà al rinnovo in data ${expiration_date} al costo di ${Utility.currency(subscription.next_plan.amount)} + IVA.`
                : `L'abbonamento si rinnoverà automaticamente in data ${expiration_date} al costo di ${Utility.currency(subscription.next_renewal_price)} + IVA.`;

        return (
            <div className="card-container" style={{ borderColor: colors.border }}>
                <div className="row" style={{}}>
                    <img src={subscriptionIcon(subscription)} className="subscription-icon-profile" />
                    <h3 className="title" style={{ color: colors.black }}>{subscription.title}</h3>
                </div>
                <p className="pay-method">Modalità di pagamento: <strong>{Utility.paymentMethodTitle(subscription.payment_method!)}</strong></p>
                <p className="description">{description}</p>

                <div className="row" style={{ justifyContent: 'space-between', marginTop: 8 }}>
                    <div>
                        {subscription.type != SubscriptionType.BUNDLE && !subscription.unsubscribed && subscription.payment_method != 'trial' && subscription.next_plan == null && (
                            <div className="button-container" onClick={() => { setChangePlanModal(true) }}>
                                <button className="button-secondary">Cambia piano</button>
                            </div>
                        )}
                    </div>

                    <div>
                        {!subscription.unsubscribed && (
                            <div className="button-container" onClick={() => { setCancelSubscriptionsPopup(true) }}>
                                <button className="button-secondary" style={{ color: 'red' }}>Disdici abbonamento</button>
                            </div>
                        )}
                    </div>

                </div>
            </div>
        );
    };


    return (
        <div>
            {cancelSubscriptionsPopup && subscription &&
                <ConfirmModal content={cancelSubscriptionPopupView(subscription)} />}

            {changePlanModal &&
                <Modal>
                    <SubscriptionsEdit
                        sub={subscription}
                        onCancel={() => setChangePlanModal(false)}
                        onPress={(plan) => {
                            console.log("PLAN", plan!.id!, subscription.id!)
                            changePlanRequest(subscription.id!, plan!.id!)
                        }} />
                </Modal>
            }

            {renderSubscription()}
        </div>
    )
}

export default SubscriptionsEditCard;

