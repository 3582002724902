import React from "react";
import ListItem from "./SubscriptionListItem";
import collegaProfessionalLogo from "../../image/icons/brand-logo/collega-brand-professional.svg";
import collegaGoldLogo from "../../image/icons/brand-logo/collega-brand-gold.svg";
import collegaCompetenzeLogo from "../../image/icons/brand-logo/collega-brand-competenze.svg";
import strings from "values/strings";
import { SubscriptionInfoType, SubscriptionType } from "values/values";
import { SubscriptionInfo } from "store/types/SubscriptionInfo";
import Utility from "utils/utils";

interface SubscriptionCardProps {
  subscription: SubscriptionInfo;
  titleColor?: string;
  borderColor?: string;
  onClick: () => void;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  subscription,
  titleColor,
  borderColor,
  onClick,
}) => {
  const cards = strings.subscriptions.cards;

  const logoMap: Record<SubscriptionInfoType, string> = {
    [SubscriptionInfoType.PROFESSIONAL]: collegaProfessionalLogo,
    [SubscriptionInfoType.GOLD]: collegaGoldLogo,
    [SubscriptionInfoType.SKILLS]: collegaCompetenzeLogo,
    [SubscriptionInfoType.BUNDLE]: collegaCompetenzeLogo,
  };


  function convertBulletListToArray(text?: string): { item: string }[] {

    // Dividi il testo in righe
    const lines = text!.split('\n');

    // Filtra solo le righe che contengono elementi di elenco (ad esempio quelli che iniziano con '•')
    const items = lines.filter(line => line.trim().startsWith('•'));

    // Mappa ogni riga filtrata in un oggetto
    const result = items.map(line => {
      // Rimuove il simbolo dell'elenco puntato e gli spazi
      const itemText = line.replace('•', '').trim();
      return { item: itemText };
    });

    return result;
  }


  return (
    <div className="card-container-wrapper">
      <div className="card__container" style={{ borderColor, marginBottom: 20, flexGrow: 1 }} onClick={onClick}>
        <div className="card__header">
          <div className="card__title">
            <img
              src={logoMap[Utility.subscriptionType(subscription)] || collegaGoldLogo}
              alt="Collega Logo"
              className="card__icon"
            />
            <h3 style={{ color: titleColor }}>{subscription.title}</h3>
          </div>
          
        </div>

        <ul className="card__content">
          {convertBulletListToArray(subscription.description).map((item, index) => (
            <ListItem key={index} type={Utility.subscriptionType(subscription)} text={item.item} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SubscriptionCard;
