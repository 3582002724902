import { Profile, Skill } from './Profile';
import Utility from '../../utils/utils';
import { Subscription } from './Subscriptions';
import _ from 'lodash';
import { SkillsType, SubscriptionInfoType, SubscriptionType } from 'values/values';


export class User {

  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  birth_date?: string;
  phone?: string;
  fiscal_code?: string;
  password1?: string;
  password2?: string;
  under46: boolean = false;
  terms: boolean = false;
  privacy: boolean = false;
  gdpr: boolean = false;
  trial_expires_at?: string;
  subscriptions: Array<Subscription> = []

  profile: Profile = new Profile()

  created_at?: string

  is_certified: boolean = false
  is_gold: boolean = false
  is_aiga: boolean = false
  is_skills: boolean = false
  email_enabled?: boolean
  notifications_enabled?: boolean

  last_payment?: LastPayment

  temp_demand?: string
  is_new: boolean = true
  missing_popups: Array<{ id: number, name: string }> = []



  static fullNameMasked = (user: User): string => {
    return user.first_name + ' ' + user.last_name!.charAt(0) + '.'
  }

  static fullName = (user: User): string => {
    return user.first_name + ' ' + user.last_name
  }

  // Subscriptions
  static isSkill = (user?: User) => {
    if (user === undefined) { return false; }
    let _isSkill = !_.isEmpty(User.additionalSkillsSubscriptions(user));
    console.log('isSkill', _isSkill, user.is_skills)
    return _isSkill;
  }

  static isGold = (user?: User) => {
    if (user === undefined) { return false; }
    let _isGold = User.goldSubscription(user) !== undefined;
    console.log('isGold', _isGold)
    return _isGold;
  }

  static isProfessional = (user?: User) => {
    if (user === undefined) { return false; }
    let _isProfessional = User.professionalSubscription(user) !== undefined;
    console.log('isProfessional', _isProfessional)
    return _isProfessional;
  }

  static additionalSkillsSubscription = (user?: User): Subscription | undefined => {
    if (user === undefined || user.subscriptions == undefined) { return undefined; }
    return _.first(user!.subscriptions.filter((subscription: Subscription) => subscription.type === SubscriptionType.SKILLS));
  }

  static additionalSkillsSubscriptions = (user?: User): Subscription[] => {
    if (user === undefined || user.subscriptions == undefined) { return []; }
    let rootSubscriptions: Subscription[] = _.filter(user!.subscriptions.filter((subscription: Subscription) => subscription.type === SubscriptionType.SKILLS));
    let bundleSubscriptions: Subscription[] = user!.subscriptions.filter((subscription: Subscription) => subscription.type === SubscriptionType.BUNDLE);

    let additionalSkillsSubscriptions: Subscription[] = bundleSubscriptions
      .map((bundle: Subscription) => bundle.contained_subscriptions!.find(sub => sub.type === SubscriptionInfoType.SKILLS))
      .filter((sub): sub is Subscription => sub !== undefined);

    console.log('additionalSkillsSubscriptions', rootSubscriptions, additionalSkillsSubscriptions)
    return !_.isEmpty(rootSubscriptions) ? rootSubscriptions : !_.isEmpty(additionalSkillsSubscriptions) ? additionalSkillsSubscriptions : [];
  }

  static basicSkillsCount = (user?: User): number => {
    if (user === undefined) { return 0; }
    return user!.profile.skills.filter((skill: Skill) => skill.type === SkillsType.BASIC).length;
  }


  static goldSubscription = (user?: User): Subscription | undefined => {
    if (user === undefined || user.subscriptions == undefined) { return undefined; }
    let rootSubscription = _.first(user!.subscriptions.filter((subscription: Subscription) => subscription.type === SubscriptionType.GOLD));
    let bundleSubscriptions = user!.subscriptions.filter((subscription: Subscription) => subscription.type === SubscriptionType.BUNDLE);

    var goldSubscription = bundleSubscriptions.map(bundle => {
      return bundle.contained_subscriptions!.find(sub => sub.type === SubscriptionType.GOLD)
    }).find(sub => sub !== undefined);

    return rootSubscription || goldSubscription;
  }

  static professionalSubscription = (user?: User): Subscription | undefined => {
    if (user === undefined || user.subscriptions == undefined) { return undefined; }

    let rootSubscription = _.first(user!.subscriptions.filter((subscription: Subscription) => subscription.type === SubscriptionType.PROFESSIONAL));
    let bundleSubscriptions = user!.subscriptions.filter((subscription: Subscription) => subscription.type === SubscriptionType.BUNDLE);

    var proSubscription = bundleSubscriptions.map(bundle => {
      return bundle.contained_subscriptions!.find(sub => sub.type === SubscriptionType.PROFESSIONAL)
    }).find(sub => sub !== undefined);

    return rootSubscription || proSubscription;
  }

  static skillsBuyable = (user: User, skills: Array<Skill>): boolean => {
    if (!user || !skills) return false;
    const totalPurchasedSkills = user.subscriptions.reduce((total, item) => total + (item.purchased_skills || 0), 0);
    return totalPurchasedSkills < skills.length;
  }

  checkDateField() {
    return // !!this.birth_year && !!this.birth_day && this.birth_month !== '-1';
  }

  checkDateValidity() {
    return true
  }

  validateType() {
    return this.profile.type !== undefined
  }

  validateName() {
    return this.first_name !== undefined && this.last_name !== undefined;
  }

  validateCredentials() {
    return Utility.validateEmail(this.email) && Utility.validatePassword(this.password1)
  }

  validateFlag() {
    return this.privacy && this.terms;
  }

  checkSignUpValidity() {
    return this.validateType() && this.validateName() && this.validateFlag() &&
      Utility.validateEmail(this.email) && Utility.validatePassword(this.password1) &&
      Utility.validatePhone(this.phone);
  }
}

export class LastPayment {
  id?: number
  expire_at?: string
  subscription_id?: string
  payment_type?: PaymentType
  renewal?: boolean
}


export class PaymentType {
  id?: number
  description?: string
  price?: string
  months?: number
  status?: boolean
  plan_id?: string
}