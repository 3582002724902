import React, { Fragment, useEffect } from "react";
import CollegaProfessional from "./CollegaProfessional";
import CollegaGold from "./CollegaGold";
import CollegaCompetenze from "./CollegaCompetenze";
import { Link, useHistory, useLocation } from "react-router-dom";
import { SubscriptionInfoType } from "values/values";
import Utility from "utils/utils";
import { useDispatch, useSelector } from "react-redux";

import logo from '../../../image/logo-collega.svg';
import skill from '../../../image/skill__image.svg';
import gold from '../../../image/gold__image.svg';
import professional from '../../../image/professional__image.svg';
import UserService from "services/UserServices";

import _ from 'lodash';
import { ISubscriptionReducer, setPrice, setSubscription } from "store/reducers/SubscriptionReducer";
import Storage from "store/Storage";
import CollegaBundle from "./CollegaBundle";


const Subscription: React.FC = () => {

  const location = useLocation()
  const history = useHistory();

  const [subscriptionInfoResponse, subscriptionInfoError, subscriptionInfoRequest] = UserService.useSubscriptionsInfoAPI()
  const [user, , userRequest] = UserService.useUserAPI()


  const subscription = useSelector((state: ISubscriptionReducer) => state.subscription.subscription);
  const dispatch = useDispatch();


  const contentMap = {
    [SubscriptionInfoType.PROFESSIONAL]: <CollegaProfessional subscriptionInfo={subscription!} />,
    [SubscriptionInfoType.GOLD]: <CollegaGold />,
    [SubscriptionInfoType.SKILLS]: <CollegaCompetenze subscriptionInfo={subscription!} />,
    [SubscriptionInfoType.BUNDLE]: <CollegaBundle subscriptionInfo={subscription!} subscriptions={subscriptionInfoResponse} />,
  };

  const imageMap = {
    [SubscriptionInfoType.PROFESSIONAL]: professional,
    [SubscriptionInfoType.GOLD]: gold,
    [SubscriptionInfoType.SKILLS]: skill,
    [SubscriptionInfoType.BUNDLE]: skill,
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    let token: string | null = queryParams.get('token')

    if (location) {
      if (token != undefined) {
        Storage.set(Storage.AUTH_TOKEN, token).then(() => {
          userRequest() // Mobile only
        })

      } else {
        subscriptionInfoRequest() // Web only
      }
    }
  }, [location])

  useEffect(() => {
    if (user == undefined) return
    subscriptionInfoRequest()

  }, [user])

  useEffect(() => {
    if (subscriptionInfoResponse) {
      const queryParams = new URLSearchParams(location.search);
      let subscription_type = queryParams.get('type')
      let subscription_id = queryParams.get('id')

      let _subscription = _.first(subscriptionInfoResponse.filter((s) => s.type == subscription_type && (subscription_id != undefined ? s.id == Number(subscription_id) : true)))
      dispatch(setSubscription(_subscription))
    }

  }, [subscriptionInfoResponse])


  // useEffect(() => {
  //   if (location.state.subscription_type && !subscription) window.location.href = '/dashboard'
  // }, [subscription])

  const handleContinue = () => {
    if (subscription == undefined) return

    switch (subscription.type) {

      case SubscriptionInfoType.BUNDLE:
        history.push("/subscription-plan");
        break;

      case SubscriptionInfoType.PROFESSIONAL:
        history.push("/account-confirm", { subscription: subscription });
        break;

      case SubscriptionInfoType.GOLD:
        dispatch(setPrice(Number(subscription.price)))
        history.push("/subscription-plan");
        break;

      case SubscriptionInfoType.SKILLS:
        history.push("/subscription-plan");
        break;
    }
  };


  return (
    <Fragment>
      <div className={'header-subscription'}>
        <Link to={'/dashboard'}><img className={'logo subscription-logo'} src={logo} alt="Logo collega" /></Link>
      </div>
      <div className={'wrapper-login'}>
        {subscription && subscription && <div className={'login'} style={{ paddingTop: '30px' }}>
          <div style={{ position: 'relative' }} className={'login__form'}>

            {contentMap[Utility.subscriptionType(subscription)]}

            <button
              title={"Continua"}
              className={'button-primary'}
              onClick={handleContinue}>
              <span>{'Prosegui'}</span>
            </button>

          </div>
          <div className={'login__image'}>
            <img style={{ width: 450 }} src={imageMap[Utility.subscriptionType(subscription)]} alt="Logo collega" />
          </div>
        </div>}
      </div>
    </Fragment>
  );
};

export default Subscription;
