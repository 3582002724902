import React, { FC, useEffect, useState } from 'react';
import { User } from 'store/types/User';
import gold from '../../image/icons/activity/collega-gold.svg';
import SkillProfileItem from './SkillProfileItem';
import _ from 'lodash';
import swap from '../../image/swap.svg';
import { FormFields, SubscriptionInfoType, SubscriptionType } from 'values/values';
import strings from 'values/strings';
import UserService from 'services/UserServices';
import InputAutosuggest from '../common/AutoSuggest'
import { useDispatch, useSelector } from 'react-redux';
import { createTempUser, IUserReducer, updateTempUser } from 'store/reducers/UserReducer';
import toast from 'react-hot-toast';
import trash from '../../image/icons/basket.svg';
import Utility from 'utils/utils';
import ConfirmModal from 'components/request-activities/modal/ConfirmModal';
import { ActivityModal } from 'store/types/ActivityModal';
import moment from 'moment';


type Props = {
    currentUser?: User;
    onPress?: () => void
    onEdit?: () => void
}

const ProfessionalSkill: FC<Props> = (props) => {

    const [citiesResponse, , citiesRequest] = UserService.useSearchCitiesAPI();
    const [city2OldPriceResponse, , city2OldPriceRequest] = UserService.useCityPriceAPI()
    const [city2NewPriceResponse, , city2NewPriceRequest] = UserService.useCityPriceAPI()
    const [subscriptionInfoResponse, subscriptionInfoError, subscriptionInfoRequest] = UserService.useSubscriptionsInfoAPI()
    let [updateCity2Response, updateCity2Error, updateCity2Request] = UserService.useUpdateCity2API()

    let [updateResponse, updateError, updateRequest] = UserService.useUpdateUserMeAPI()

    const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);
    const tempUser = useSelector((state: IUserReducer) => state.user.tempUser);

    const [canSave, setCanSave] = useState<boolean>(false)

    // City2Change
    const [showWarnings, setShowWarnings] = useState(false);
    const [oldCost, setOldCost] = useState(0);
    const [newCost, setNewCost] = useState(0);
    const [city2Data, setCity2Data] = useState<any>({ value: undefined });

    const [lawfirmCity2, setLawfirmCity2] = useState<any>({
        query: currentUser && currentUser!.profile.lawfirm.city2 != undefined ? currentUser.profile.lawfirm.city2.display_name : '',
        object: currentUser && currentUser!.profile.lawfirm.city2 != undefined ? currentUser.profile.lawfirm.city2 : undefined
    })
    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(createTempUser(currentUser))
        subscriptionInfoRequest()
    }, [])

    useEffect(() => {
        if (updateCity2Response) {
            updateLegalOffice(FormFields.city2, city2Data.value);
            setCanSave(true);
        }
    }, [updateCity2Response])

    useEffect(() => {
        if (currentUser != undefined && User.isProfessional(currentUser)) {
            if (currentUser.profile.lawfirm.city2 != undefined) {
                city2OldPriceRequest(`${currentUser.profile.lawfirm.city2.id}`)
            } else {
                city2OldPriceRequest(`${currentUser.profile.city!.id}`)
            }
        }
    }, [currentUser])

    useEffect(() => {
        if (city2OldPriceResponse) {
            setOldCost(Number(city2OldPriceResponse.price))
        }
    }, [city2OldPriceResponse])

    useEffect(() => {
        if (city2NewPriceResponse) {
            setNewCost(Number(city2NewPriceResponse.price))
        }
    }, [city2NewPriceResponse])

    useEffect(() => {
        console.log("newCost", newCost)
        if (newCost == 0) return
        if (newCost == oldCost || User.professionalSubscription(currentUser)!.payment_method == 'trial') {
            updateLegalOffice(FormFields.city2, city2Data.value);
            setCanSave(true);
        } else {
            setShowWarnings(true)
        }
    }, [newCost])

    useEffect(() => {
        if (updateResponse) toast.success("Secondo comune aggiornato!")
    }, [updateResponse])

    useEffect(() => {
        if (canSave) {
            updateRequest(tempUser!)
        }
    }, [canSave])

    const availableProfessionalSkills = () => {
        const { currentUser } = props;

        // Check if currentUser and profile exist
        if (!currentUser || !currentUser.profile) {
            return 0; // Return 0 if currentUser is undefined
        }

        const professionalSkills = currentUser.profile.skills.filter((item) => item.type === SubscriptionType.PROFESSIONAL.toUpperCase()) || [];

        // Get the professional subscription safely
        const subscription = User.professionalSubscription(currentUser);

        // Check if subscription exists before accessing purchased_skills
        const availableProfessionallSkills = subscription ? subscription.purchased_skills! - professionalSkills.length : 0;

        return availableProfessionallSkills;
    };

    const professionalSkills = () => {
        if (props.currentUser === undefined) { return [] }
        return props.currentUser.profile.skills.filter((item) => item.type === SubscriptionType.PROFESSIONAL.toUpperCase());
    };

    let updateProfile = async (field: string, value: any) => {
        let data: any = {
            ...tempUser!.profile,
            [field]: value
        }

        await dispatch(updateTempUser(FormFields.profile, data))
    }

    let updateLegalOffice = async (field: string, value: any) => {
        let data = {
            ...tempUser!.profile.lawfirm,
            [field]: value
        }

        await updateProfile(FormFields.lawfirm, data)
        setNewCost(0)
    }

    let handleCancel = () => {
        setLawfirmCity2({
            query: currentUser && currentUser!.profile.lawfirm.city2 != undefined ? currentUser.profile.lawfirm.city2.display_name : '',
            object: currentUser && currentUser!.profile.lawfirm.city2 != undefined ? currentUser.profile.lawfirm.city2 : undefined
        })
    }


    let calculateMonthsDifference = (expireAt?: string, activationDate?: string): number => {
        const expireDate = moment(expireAt, "YYYY-MM-DD");
        const activationDateObj = moment(activationDate, "YYYY-MM-DD");
        return expireDate.diff(activationDateObj, 'months'); // Avvisare Mirko
    }

    let percentaged = (value: number, percentage: number) => {
        return value - (value / 100 * percentage)
    }

    const warningsPopup = (): ActivityModal => {

        let professional_subscription = User.professionalSubscription(currentUser)!
        console.log("professional_subscription", professional_subscription)
        let sub_months = professional_subscription.months!
        let discount = subscriptionInfoResponse ? subscriptionInfoResponse.filter((s) => s.type == SubscriptionInfoType.PROFESSIONAL)[0].plans!.filter((p) => p.months == sub_months)[0].percentage! : 0
       
       console.log("professional_subscription.expire_at", professional_subscription.expire_at, "professional_subscription.activation_date", professional_subscription.activation_date)
        let remain_months = calculateMonthsDifference(professional_subscription.expire_at, professional_subscription.activation_date)

        console.log("newCost", newCost, "oldCost", oldCost, "discount", discount, "sub_months", sub_months, "remain_months", remain_months)
        let new_total = percentaged(newCost, discount) * sub_months
        let old_total = percentaged(oldCost, discount) * sub_months
        let diff_total = percentaged(newCost - oldCost, discount) * remain_months


        let title = 'Attenzione!'
        var copy = ''

        if (newCost < oldCost) {
            copy = `Il comune selezionato ha un costo inferiore rispetto al precedente. La variazione di prezzo si applicherà dal prossimo rinnovo e pagherai ${Utility.currency(new_total)} anziché ${Utility.currency(old_total)}.`
        } else {
            copy = `Il comune selezionato ha un costo superiore rispetto al precedente.\n\nConfermando verrà addebitato un importo aggiuntivo di ${Utility.currency(diff_total)} e dal prossimo rinnovo il costo dell'abbonamento sarà di ${Utility.currency(new_total)}.`
        }

        return {
            title: title,
            cta: 'Conferma',
            ctaUndo: 'Annulla',
            copy: copy,
            onConfirm: (() => {
                setShowWarnings(false)
                updateCity2Request(`${city2Data.value!.id}`, `${User.professionalSubscription(currentUser)!.id}`)
            }),
            onCancel: () => {
                setShowWarnings(false)
                handleCancel()
            }
        }
    }


    return (
        <>
            <div className="specific-container">
                <div className="specific-header">
                    {/* Left side (Icon and text) */}
                    <div className="left-content">
                        <img src={gold} alt="collega" style={{ marginRight: '8px', width: 20, height: 20 }} />
                        <span>Collega professional</span>
                    </div>

                    <img
                        src={swap}
                        alt="swap-icon"
                        onClick={props.onEdit}
                    />
                </div>

                <div className='text-certified-container'>
                    <span className='text-certified'>Il secondo comune in cui operi è:</span>
                </div>

                <div className='text-certified-container'>
                    <form className="full-width-form">
                        <InputAutosuggest
                            hasIcon={false}
                            placeholder={strings.profileEdit.step.lawfirm.input.city2.placeholder}
                            suggestions={citiesResponse || []}
                            onChange={(v) => {
                                setLawfirmCity2({ query: v, object: undefined });
                            }}
                            onSelect={(data) => {
                                console.log("NEW CITY", data)
                                setCity2Data(data)
                                city2NewPriceRequest(`${data.value.id}`)

                            }}
                            value={lawfirmCity2.query}
                            onFetch={citiesRequest}
                        />
                        {showWarnings && <ConfirmModal content={warningsPopup()} />}
                    </form>

                    <div
                        style={{ paddingLeft: 16, paddingTop: 16, paddingBottom: 16, cursor: 'pointer' }}
                        onClick={() => {
                            setLawfirmCity2({ query: undefined, object: undefined });
                            updateLegalOffice(FormFields.city2, null);
                            setCanSave(true);
                        }}>
                        <img src={trash} alt="arrow" />
                    </div>
                </div>

                <div className={'secondary-divider'}></div>

                <div>
                    {/* Show additional skill */}
                    {professionalSkills().map((skill, index, array) => {
                        const isLast = index === array.length - 1;
                        return (
                            <div key={_.uniqueId()}>
                                <div style={{ flex: 1 }}>
                                    <SkillProfileItem
                                        skill={skill}
                                        isGold={User.isGold(props.currentUser)}
                                        onPress={props.onPress}
                                    />
                                </div>

                                {!isLast && <div className='third-divider' />}
                            </div>

                        );
                    })}
                    {availableProfessionalSkills() != undefined && availableProfessionalSkills()! > 0 && (
                        <p className='text'>
                            Puoi aggiungere ancora {availableProfessionalSkills()}{' '}
                            {availableProfessionalSkills() === 1 ? 'competenza' : 'competenze'}.
                        </p>
                    )}

                    {availableProfessionalSkills() != undefined && availableProfessionalSkills()! > 0 && <div className={'button__'}>
                        <button
                            className={'button-secondary'}
                            onClick={props.onPress}
                        >{props.currentUser!.profile.skills.filter((item) => item.is_main === false).length < 3
                            ? 'Seleziona competenze'
                            : 'Aggiungi competenze'}</button>
                    </div>}
                </div>
            </div>

        </>
    );
};

export default ProfessionalSkill;




