import React from "react";
import { ReactComponent as CheckIcon } from "../../image/icons/circle-check.svg";
import { SubscriptionInfoType, SubscriptionType } from "values/values";

interface ListItemProps {
  text: string;
  type: SubscriptionInfoType;
}

const ListItem: React.FC<ListItemProps> = ({
  text,
  type,
}) => {
  const correctItemColor = () => {
    switch (type) {
      case SubscriptionInfoType.PROFESSIONAL:
        return "rgba(236, 200, 79, 1)";
      case SubscriptionInfoType.GOLD:
        return "rgba(171, 130, 83, 1)";
      case SubscriptionInfoType.SKILLS:
        return "rgba(32, 138, 134, 1)";
      case SubscriptionInfoType.BUNDLE:
        return "rgba(32, 138, 134, 1)";
      default:
        return "black";
    }
  };
  return (
    <li className="card-list-item">
      <CheckIcon
        className="card-list-icon"
        style={{ color: correctItemColor() }}
      />
      <span>{text}</span>
    </li>
  );
};

export default ListItem;
