import React, { useState, useEffect } from 'react';
import 'moment/locale/it'
import news_placeholder from '../../image/icons/news_placeholder.svg'
import AnalyticsService, { AnalyticsEvents } from 'services/AnalyticsService';
import Network from 'network/Network';


interface Props {
    externalProduct: any
}

let ExternalProduct: React.FC<Props> = React.memo(({ externalProduct }) => {

    let [image, setImage] = useState<string>()

    useEffect(() => {
        setImage(Network.baseURL + externalProduct.image || news_placeholder)
    }, [externalProduct.image])


    const price = externalProduct.amount ? `${externalProduct.amount} € + IVA` : 'Scopri il servizio';

    return (
        <a className={'news__card'} href={externalProduct.link} onClick={() => AnalyticsService.event(AnalyticsEvents.newsOpen, { news_id: externalProduct.id })} target={'_blank'}>
            <div className={'img__container'}>
                <img src={image} alt={externalProduct.name} onError={() => setImage(news_placeholder)} />
            </div>

            <div className="text">
                <p className="title">{externalProduct.name}</p>
                <p className="price">{price}</p>
                <p className="description">{externalProduct.description.substring(0, 70)}..</p>
            </div>
        </a>
    );
});

export default ExternalProduct;

