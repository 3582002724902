import { Variant } from 'store/types/Variant';
import { SubscriptionInfo } from 'store/types/SubscriptionInfo';
import { Subscription } from 'store/types/Subscriptions';
import { User } from 'store/types/User';
import { SubscriptionInfoType, SubscriptionType } from 'values/values';
import _ from 'lodash';
const SET_CITY_ID = 'SET_CITY_ID';
const SET_PRICE = 'SET_PRICE';
const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
const SET_SELECTED_VARIANT = 'SET_SELECTED_VARIANT';
const SET_PROMO_DISCOUNT = 'SET_PROMO_DISCOUNT';
const SET_PROMO_PERCENTAGE = 'SET_PROMO_PERCENTAGE';
const SET_PROMO_CODE = 'SET_PROMO_CODE';
const SET_BUNDLES = 'SET_BUNDLES';
const SET_PURCHASED_BUNDLES = 'SET_PURCHASED_BUNDLES';
const SET_PURCHASEABLE_BUNDLES = 'SET_PURCHASEABLE_BUNDLES';
export interface ISubscriptionReducer {
  subscription: ISubscriptionReducerState;
}

export interface ISubscriptionReducerState {
  promoDiscount?: string
  promoPercentage?: number
  promoCode?: string
  variant?: Variant
  city_id?: number
  price?: number
  subscription?: SubscriptionInfo
  bundles?: SubscriptionInfo[]
  purchasedBundles?: SubscriptionInfo[]
  purchaseableBundles?: SubscriptionInfo[]
}


// ACTIONS

export function setPromoPercentage(value: any) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_PROMO_PERCENTAGE,
      promoPercentage: value
    });
  };
}

export function setPromoDiscount(value: any) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_PROMO_DISCOUNT,
      promoDiscount: value
    });
  };
}

export function setPromoCode(value: string) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_PROMO_CODE,
      promoCode: value
    });
  };
}

export function setBundles(currentUser: User, bundles?: SubscriptionInfo[]) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_BUNDLES,
      bundles
    });

    dispatch(setPurchaseableBundles(currentUser, bundles))
  };
}

export function setPurchaseableBundles(currentUser: User, bundles?: SubscriptionInfo[]) {

  let purchaseableBundles = bundles!.filter((bundle: SubscriptionInfo) => {
    if (!bundle.contained_subscriptions || !currentUser!.subscriptions) return false;

    let isContainedSome: boolean[] = []

    _.forEach(bundle.contained_subscriptions, (bundleContainedSub: SubscriptionInfo) => {
      _.forEach(currentUser!.subscriptions, (userSub: Subscription) => {
        if (userSub.type === SubscriptionType.BUNDLE) {
          // Check if user has a bundle containing gold or professional subscription
          const hasGold = _.some(userSub.contained_subscriptions, sub => sub.type === SubscriptionType.GOLD)
          const hasPro = _.some(userSub.contained_subscriptions, sub => sub.type === SubscriptionType.PROFESSIONAL)
          
          let isContained = (hasGold && bundleContainedSub.type === SubscriptionInfoType.GOLD) || (hasPro && bundleContainedSub.type === SubscriptionInfoType.PROFESSIONAL)
          isContainedSome.push(isContained)
        }

        // Otherwise check if subscription types match
        let isContained = bundleContainedSub.type === userSub.type
        isContainedSome.push(isContained)
      })
    })
    console.log("isContainedSome", bundle.id, bundle.contained_subscriptions.map((sub: SubscriptionInfo) => sub.type), isContainedSome)
    return !isContainedSome.includes(true)
  })

  console.log("purchaseableBundles", purchaseableBundles)

  return async (dispatch: any) => {
    await dispatch({
      type: SET_PURCHASEABLE_BUNDLES,
      purchaseableBundles: purchaseableBundles
    });
  };
}

export function setCityId(city_id?: number) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_CITY_ID,
      city_id
    });
  };
}

export function setPrice(price?: number) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_PRICE,
      price: price
    });
  };
}

export function setSubscription(subscription?: SubscriptionInfo) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_SUBSCRIPTION,
      subscription: subscription
    });
  };
}

export function setSelectedVariant(variant?: Variant) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_SELECTED_VARIANT,
      variant
    });
  };
}


// REDUCER
export default function (state: ISubscriptionReducerState = {}, action: any) {
  switch (action.type) {
    case SET_PROMO_DISCOUNT:
      return {
        ...state,
        promoDiscount: action.promoDiscount
      };

    case SET_PROMO_PERCENTAGE:
      return {
        ...state,
        promoPercentage: action.promoPercentage
      };

    case SET_PROMO_CODE:
      return {
        ...state,
        promoCode: action.promoCode
      };

    case SET_CITY_ID:
      return {
        ...state,
        city_id: action.city_id
      };

    case SET_BUNDLES:
      return {
        ...state,
        bundles: action.bundles
      };

    case SET_PRICE:
      return {
        ...state,
        price: action.price
      };

    case SET_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.subscription
      };

    case SET_SELECTED_VARIANT:
      return {
        ...state,
        variant: action.variant
      };

    case SET_PURCHASED_BUNDLES:
      return {
        ...state,
        purchasedBundles: action.purchasedBundles
      };

    case SET_PURCHASEABLE_BUNDLES:
      return {
        ...state,
        purchaseableBundles: action.purchaseableBundles
      };

    default:
      return state;
  }
}
