import React, { useEffect, useState } from "react";
import Select from "components/common/Select";
import strings from "values/strings";
import SelectOption from "store/types/SelectionOption";
import { SubscriptionInfo } from "store/types/SubscriptionInfo";
import { Variant } from "store/types/Variant";
import { useDispatch, useSelector } from "react-redux";
import { setCityId, setPrice, setSelectedVariant } from "store/reducers/SubscriptionReducer";
import { SubscriptionInfoType } from "values/values";
import colors from "values/colors";
import arrow from 'image/icons/nav/arrow-grey-secondary.svg';
import UserService from "services/UserServices";
import { IUserReducer } from "store/reducers/UserReducer";
import InputAutosuggest from '../../common/AutoSuggest'
import SubscriptionListItem from "../SubscriptionListItem";
import Utility from "utils/utils";

interface Props {
  subscriptionInfo: SubscriptionInfo
  subscriptions: SubscriptionInfo[]
}

const CollegaBundle: React.FC<Props> = ({ subscriptionInfo, subscriptions }) => {
  const collega = strings.subscriptions.collega;

  // const [cityPriceResponse, , cityPriceRequest] = UserService.useCityPriceAPI()
  const [cityPriceForBundleResponse, , cityPriceForBundleRequest] = UserService.useCityPriceForBundleAPI()

  const [citiesResponse, , citiesRequest] = UserService.useSearchCitiesAPI();
  const subscriptionInfoDescriptionArray = subscriptionInfo != undefined ? subscriptionInfo.description!.split('• ').filter(Boolean) : []

  const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);

  const dispatch = useDispatch()

  const [city1Price, setCity1Price] = useState<any>()
  const [city2Price, setCity2Price] = useState<any>()
  const [maxPrice, setMaxPrice] = useState<any>()

  const [lawfirmCity2, setLawfirmCity2] = useState<any>({
    query: '',
    object: undefined
  })

  // Get city price
  useEffect(() => {
    if (currentUser) {
      cityPriceForBundleRequest(subscriptionInfo.id!.toString(), currentUser.profile.lawfirm.city!.id!.toString())
    }
  }, [currentUser])

  // useEffect(() => {
  //   if (cityPriceResponse) {
  //     if (lawfirmCity2.object == undefined) {
  //       setCity1Price({
  //         city: currentUser!.profile.lawfirm.city!.id,
  //         price: cityPriceResponse.price
  //       })

  //     } else {
  //       setCity2Price({
  //         city: lawfirmCity2.object.id,
  //         price: cityPriceResponse.price
  //       })
  //     }
  //   }
  // }, [cityPriceResponse])

  useEffect(() => {
    if (cityPriceForBundleResponse) {
      dispatch(setPrice(Number(cityPriceForBundleResponse.price)))
    }
  }, [cityPriceForBundleResponse])

  useEffect(() => {
    if (lawfirmCity2.object != undefined) {
      cityPriceForBundleRequest(subscriptionInfo.id!.toString(), lawfirmCity2.object.id.toString())
    }
  }, [lawfirmCity2])

  // useEffect(() => {
  //   if (city1Price != undefined) {
  //     if (city2Price != undefined) {
  //       if (Number(city1Price.price) >= Number(city2Price.price)) setMaxPrice(city1Price)
  //       if (Number(city1Price.price) <= Number(city2Price.price)) setMaxPrice(city2Price)

  //     } else {
  //       setMaxPrice(city1Price)
  //     }
  //   }
  // }, [city1Price, city2Price])

  // useEffect(() => {
  //   console.log(maxPrice)
    
  //   if (maxPrice != undefined) {
  //     console.log(maxPrice.price)
  //     dispatch(setCityId(maxPrice.city))
  //     dispatch(setPrice(Number(maxPrice.price)))
  //   }
  // }, [maxPrice])
  // 


  // const [selectedOption, setSelectedOption] = useState<SelectOption<Variant>>()

  // Aggiungi questo stato per tenere traccia di quali accordion sono aperti
  const [openAccordions, setOpenAccordions] = useState<number[]>([]);

  // Funzione per gestire l'apertura/chiusura degli accordion
  const toggleAccordion = (index: number) => {
    setOpenAccordions(prev =>
      prev.includes(index)
        ? prev.filter(i => i !== index)
        : [...prev, index]
    );
  };

  // useEffect(() => {
  //   if (selectedOption) {
  //     var price = 0
  //     if (selectedOption.value.price == null) {
  //       price = Number(subscriptionInfo.price)
  //     } else {
  //       price = Number(selectedOption.value.price)
  //     }

  //     // dispatch(setPrice(price))
  //     dispatch(setSelectedVariant(selectedOption.value))
  //   }
  // }, [selectedOption])


  // const getOiginalPrice = (variant: Variant) => {
  //   if (variant.price != null) {
  //     return Number(subscriptionInfo.price) * Number(variant.skills_number)
  //   } else return null
  // }


  return (
    <div className="collega-details">

      {/* // Bundle title */}
      <h1 style={{ marginBottom: 0 }}>{subscriptionInfo.title}</h1>

      <p style={{ marginTop: 0, marginBottom: 16 }}>

        <div className="collega-details__description" style={{ marginBottom: 32 }}>
          <p>{subscriptionInfo.description}</p>
        </div>


        {/* // Bundle contained subscriptions */}
        <div className="collega-details__contained-subscriptions">
          {subscriptionInfo && subscriptionInfo.contained_subscriptions!.map((subscription, index) => {
            const isOpen = openAccordions.includes(index);

            return <div className="accordion" key={index}>
              <div className="accordion-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 4, marginTop: 16 }} onClick={() => toggleAccordion(index)}>
                <h3 style={{ color: colors.appointment }}>{subscription.title}</h3>
                <div className="accordion-toggle" style={{ marginLeft: 'auto' }}>
                  <img
                    src={arrow}
                    alt="arrow"
                    style={{
                      cursor: 'pointer',
                      transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.3s ease',
                    }}
                    className="arrow-icon"
                  />
                </div>
              </div>
              {isOpen && (
                <div className="accordion-content">
                  {subscriptionInfo && (subscription.description!.split('• ').filter(Boolean)).map((description, index) => (
                    <SubscriptionListItem key={index} type={Utility.subscriptionType(subscription)} text={description} />
                  ))}
                </div>
              )}
            </div>
          })}
        </div>


      </p>


      {/* // Professional city selection */}
      {subscriptionInfo.contained_subscriptions!.find((v) => v.type == SubscriptionInfoType.PROFESSIONAL) != undefined && <div>

        <form className="collega-details__form">

          <div className="select-container">

            <InputAutosuggest
              hasIcon={false}
              label={'Scegli il secondo comune in cui operi'}
              placeholder={'Nessuna città'}
              suggestions={citiesResponse || []}
              onChange={(v: any) => setLawfirmCity2({ query: v, object: undefined })}
              onSelect={(data: any) => setLawfirmCity2({ query: data.name, object: data.value })}
              value={lawfirmCity2.query}
              onFetch={citiesRequest}
              error={''} />
          </div>
        </form>

        <span className="collega-details__hint">
          {collega.professional.select.hint}
        </span>
      </div>}

    </div>
  );
};

export default CollegaBundle;
